import {
  Link,
  Routes,
  Route,
  useParams,
} from 'react-router-dom';
import {
  useEffect,
  useState,
} from 'react';
import * as contentful from 'contentful';
import YouTube from 'react-youtube';
import getYouTubeId from 'get-youtube-id';
import dayjs from 'dayjs';
import './App.css';

const videoHeight = 800
const videoWidth = videoHeight * 9 / 16

const client = contentful.createClient({
  space: 'zpk2vlibe7ni',
  accessToken: 'xk3sVDWuPqs5MqbLWXi4Y8srswW8CAosMs_d4iJk4ZE',
})

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:slug" element={<RecipePage />} />
      </Routes>
    </div>
  );
}

function RecipePage() {
  const [recipe, setRecipe] = useState({})

  const params = useParams()
  const {
    slug,
  } = params

  useEffect(() => {
    const fetchRecipe = async () => {
      const {items} = await client.getEntries({
        content_type: 'recipe',
        'fields.slug': slug,
      })

      setRecipe(items[0].fields)
    }

    fetchRecipe()
  }, [slug])

  return (
    <Recipe
      header={
        <header>
          <h1>
            <Link to="/">🏯 Home</Link>
          </h1>
        </header>
      }
      recipe={recipe}
    />
  )
}

function Home() {
  const [recipes, setRecipes] = useState([])

  useEffect(() => {
    const fetchRecipes = async () => {
      const {items} = await client.getEntries({content_type: 'recipe'})

      setRecipes(items.map((i) => i?.fields))
    }

    fetchRecipes()
  }, [])

  return (
    <Recipe
      header={
        <header
          className="home"
        >
          <h1>Umami Alex</h1>
          <h3>Recipes</h3>
          <ul>
            {
              recipes.map((r) => (
                <li>
                  <Link to={`/${r?.slug}`}>{r?.title}</Link>
                </li>
              ))
            }
          </ul>
        </header>
      }
      recipe={recipes[0]}
    />
  )
}

function Recipe({
  header,
  recipe,
}) {
  const videoId = getYouTubeId(recipe?.videoUrl)
  const d = recipe?.steps?.reduce((sum, {fields: s}) => {
    if (s?.duration) {
      return sum + s?.duration
    }

    return sum
  }, 0)
  const duration = `${Math.floor(d / 60)}h ${d % 60}m`

  return (
    <div
      className="page"
    >
      {header}
      <main
        className="recipe"
      >
        <YouTube
          className="yt-short"
          containerClassName="yt-container"
          opts={{
            height: videoHeight,
            width: videoWidth,
            playerVars: {
              autoplay: 1,
              controls: 0,
              disablekb: 1,
              loop: 1,
              playlist: videoId,
            },
          }}
          videoId={videoId}
        />
        <section
          className="text"
        >
          <h2 className="title">{recipe?.title}</h2>
          <p className="date">
            📆 {dayjs(recipe?.publishDate).format('MMMM D, YYYY')}
          </p>
          <p className="cook-time">
            ⏲️ {duration}
          </p>
          <h3>Ingredients</h3>
          <ul className="ingredients">
            {
              recipe?.ingredients?.map(
                ({fields: ingredient}, i) => <Ingredient ingredient={ingredient}/>,
              )
            }
          </ul>
          <h3>Instructions</h3>
          <ol className="steps">
            {
              recipe?.steps?.map(
                ({fields: step}, i) => <Step step={step}/>,
              )
            }
          </ol>
        </section>
      </main>
    </div>
  )
}

function Ingredient({
  ingredient,
}) {
  const {
    name,
  } = ingredient

  return (
    <li className="ingredient">{name}</li>
  )
}

function Step({
  step,
}) {
  const {
    duration: d,
    instructions
  } = step

  const duration = d
    ? <i className="step-duration">
        [{d >= 60 ? `${d/60}h`: `${d}m`}]
      </i>
    : null

  return (
    <li className="step">
      <span className="step-instructions">
        {instructions}
        {duration}
      </span>
    </li>
  )
}

export default App;
